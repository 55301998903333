import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Table} from '@app/domain/table';
import {SetOrder, SetTable, SetTableTarget, SetUser} from '@app/state/restaurant/comandero/comandero-restaurante.actions';
import {OrderRestaurant} from '@app/domain/restaurante/order-restaurant';
import {Injectable} from '@angular/core';
import { User } from '@app/domain/user';

export class ComanderoRestaurantStateModel {
    table: Table;
    tableTarget: Table;
    order: OrderRestaurant;
    user: User;
}

@Injectable()
@State<ComanderoRestaurantStateModel>({
    name: 'restaurant_comandero',
    defaults: {
        table: undefined,
        tableTarget: undefined,
        order: undefined,
        user: undefined
    }
})
export class ComanderoRestauranteState {

    constructor() {
    }

    @Selector()
    static table(state: ComanderoRestaurantStateModel) {
        return state.table;
    }

    @Selector()
    static tableTarget(state: ComanderoRestaurantStateModel) {
        return state.tableTarget;
    }

    @Selector()
    static order(state: ComanderoRestaurantStateModel) {
        return state.order;
    }

    @Selector()
    static user(state: ComanderoRestaurantStateModel) {
        return state.user;
    }


    @Action(SetTable)
    setTable({getState, setState}: StateContext<ComanderoRestaurantStateModel>,
             {table}: SetTable) {
        const state = getState();
        setState({
            ...state,
            table,
        });
    }

    @Action(SetTableTarget)
    setTableTarget({getState, setState}: StateContext<ComanderoRestaurantStateModel>,
                   {tableTarget}: SetTableTarget) {
        const state = getState();
        setState({
            ...state,
            tableTarget,
        });
    }

    @Action(SetOrder)
    setOrder({getState, setState}: StateContext<ComanderoRestaurantStateModel>,
             {order}: SetOrder) {
        const state = getState();
        setState({
            ...state,
            order,
        });
    }


    @Action(SetUser)
    setUser({getState, setState}: StateContext<ComanderoRestaurantStateModel>,
             {user}: SetUser) {
        const state = getState();
        setState({
            ...state,
            user,
        });
    }
}
