import {Table} from '@app/domain/table';
import {OrderRestaurant} from '@app/domain/restaurante/order-restaurant';
import { User } from '@app/domain/user';


export class SetTable {
    static readonly type = '[ComanderoRestaurant] SetTable';

    constructor(public table: Table) {
    }
}

export class SetTableTarget {
    static readonly type = '[ComanderoRestaurant] SetTableTarget';

    constructor(public tableTarget: Table) {
    }
}

export class SetOrder {
    static readonly type = '[ComanderoRestaurant] SetOrder';

    constructor(public order: OrderRestaurant) {
    }
}

export class SetUser {
    static readonly type = '[ComanderoRestaurant] SetUser';

    constructor(public user: User) {
    }
}
