import {Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { MenuService } from './app.menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import {AppComponent} from './app.component';
import {SesionState} from '@app/state/sesion/sesion.state';
import {Observable, Subject,interval} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {LoginService} from '@app/service/login.service';
import {UsuarioService} from '@app/service/usuario.service';
import {SocketIoService} from '@app/service/socket-io.service';
import {SetAccessToken, SetAppVersion, SetRoles} from '@app/state/sesion/sesion.actions';
import {User} from '@app/domain/user';
import {environment} from '@env/environment';
import {InstallmentService} from '@app/service/installment.service';
import {Installment} from '@app/domain/installment';
import {EventEnum} from '@app/enums/event.enum';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Office} from '@app/domain/office';
import {isMobileDevice} from "@app/utils/util-functions";

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('mask-anim', [
            state('void', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 0.8
            })),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppMainComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    @Select(SesionState.getRoles) roles$: Observable<string[]>;
    roles: string[];

    rightPanelClick: boolean;

    rightPanelActive: boolean;

    menuClick: boolean;

    staticMenuActive: boolean;

    menuMobileActive: boolean;

    megaMenuClick: boolean;

    megaMenuActive: boolean;

    megaMenuMobileClick: boolean;

    megaMenuMobileActive: boolean;

    topbarItemClick: boolean;

    topbarMobileMenuClick: boolean;

    topbarMobileMenuActive: boolean;

    sidebarActive: boolean;

    activeTopbarItem: any;

    topbarMenuActive: boolean;

    menuHoverActive: boolean;

    configActive: boolean;

    userLogin: User;

    nameCompany = environment.company;

    installmentsPending: Installment[] = [];

    @Select(SesionState.getOffice) puntoVenta$: Observable<Office>;
    puntoVenta: Office;

    constructor(public loginService: LoginService, private userService: UsuarioService,
                private store: Store, public renderer: Renderer2, private menuService: MenuService,
                private primengConfig: PrimeNGConfig, public app: AppComponent, private socketIoService: SocketIoService,
                private installmentService: InstallmentService) {}

    ngOnInit(): void {
        this.initUserLogin();
        this.loadRolesUser();
        this.getInstallments();
        this.refreshLocalToken();
        this.puntoVenta$.pipe(takeUntil(this.unsubscribe$)).subscribe(pv => {
            this.puntoVenta = pv;
            this.socketIOConnection(pv);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    refreshLocalToken() {
        // Se refresca el token cada hora
        interval(3600 * 1000)
          .pipe(
            takeUntil(this.unsubscribe$), // Se limpia el intervalo cuando se destruye el servicio/componente
            switchMap(() => this.loginService.refresh()) // Realiza la llamada al servicio para refrescar el token
          )
          .subscribe({
            next: (data) => this.store.dispatch(new SetAccessToken(data.accessToken)),
            error: (err) => console.error('Error al refrescar el token:', err)
          });
      }

    @HostListener('window:focus', ['$event'])
    onFocused(event) {
        if (!this.socketIoService.connected) {
            this.socketIoService.initSocket(this.puntoVenta);
            console.log('Re Conectado al socket');
        }
    }

    private socketIOConnection(pv: Office): void {
        this.socketIoService.initSocket(pv);
        this.socketIoService.onEvent(EventEnum.NEW_VERSION)
            .pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(data => {
            this.store.dispatch(new SetAppVersion(data));
            alert(`Nueva versión de la aplicación detectada, el navegador se actualizará de inmediato a la versión ${data}.`);
            // @ts-ignore
            location.reload(true);
        });
    }

    get esProduccion() {
        return this.loginService.getUser().empresa.esProduccion;
    }

    get razonSocial() {
        return this.loginService.getUser().empresa.razonSocial;
    }

    get nombreComercial() {
        return this.loginService.getUser().empresa.nombreComercial;
    }

    get ruc() {
        return this.loginService.getUser().empresa.ruc;
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.megaMenuClick) {
            this.megaMenuActive = false;
        }

        if (!this.megaMenuMobileClick) {
            this.megaMenuMobileActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal()) {
                this.menuService.reset();
            }

            if (this.menuMobileActive) {
                this.menuMobileActive = false;
            }

            this.menuHoverActive = false;
        }

        this.menuClick = false;
        this.topbarItemClick = false;
        this.megaMenuClick = false;
        this.megaMenuMobileClick = false;
        this.rightPanelClick = false;
    }

    onMegaMenuButtonClick(event) {
        this.megaMenuClick = true;
        this.megaMenuActive = !this.megaMenuActive;
        event.preventDefault();
    }

    onMegaMenuClick(event) {
        this.megaMenuClick = true;
        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;

        event.preventDefault();
    }

    onRightPanelClose(event) {
        this.rightPanelActive = false;
        this.rightPanelClick = false;

        event.preventDefault();
    }

    onRightPanelClick(event) {
        this.rightPanelClick = true;

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMobileMenuClick = true;
        this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

        event.preventDefault();
    }

    onMegaMenuMobileButtonClick(event) {
        this.megaMenuMobileClick = true;
        this.megaMenuMobileActive = !this.megaMenuMobileActive;

        event.preventDefault();
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isMobile()) {
            this.menuMobileActive = !this.menuMobileActive;
        }

        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    onToggleMenuClick(event: Event) {
        this.staticMenuActive = !this.staticMenuActive;
        event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig = event.checked;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isHorizontal() {
        return this.app.horizontalMenu === true;
    }

    public loadRolesUser() {
        this.userService.roles().subscribe(data => {
            this.store.dispatch(new SetRoles(data));
            this.suscribeRoles();
        });
    }

    suscribeRoles() {
        this.roles$.subscribe(roles => this.roles = roles);
    }

    initUserLogin() {
        this.userLogin = this.loginService.getUser();
    }

    getInstallments() {
        this.installmentService.findAllMe().subscribe(data => this.installmentsPending = data.filter(d => d.estado === 'PENDIENTE'));
    }

}
