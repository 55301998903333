import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Product} from '../domain/product';
import {Inventory} from '@app/domain/inventory';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/products?${params}`);
    }

    findTopOrders(params): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/products/operations/top-orders?${params}`);
    }

    findAllPage(params: string): Observable<Page<Product>> {
        return this.findAll(params).pipe(map(data => data as Page<Product>));
    }

    findAllList(params: string): Observable<Product[]> {
        return this.findAll(params).pipe(map(data => data as Product[]));
    }

    findById(id: number): Observable<Product> {
        return this.http.get<Product>(`${this.baseUrl}/products/${id}`);
    }


    save(idEmpresa, model: Product): Observable<Product> {
        return this.http.post<Product>(`${this.baseUrl}/products`, model);
    }

    update(idEmpresa, model: Product): Observable<Product> {
        return this.http.put<Product>(`${this.baseUrl}/products`, model);
    }

    deleteById(idEmpresa, id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/products/${id}`);
    }

    importFromExcel(formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/products/import`, formData);
    }

    startInventory(model: any): Observable<Inventory> {
        return this.http.post<Inventory>(`${this.baseUrl}/products/operations/startInventory`, model);
    }

    deleteByIdEmpresa(idEmpresa): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/root/products/${idEmpresa}`);
    }

    processPurchaseUnitPriceByIdEmpresa(idEmpresa): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/root/ticket-detail/process-product-price/${idEmpresa}`, null);
    }
}
