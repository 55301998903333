import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  transform(items: any[], dateField: string, order: 'asc' | 'desc' = 'desc'): any[] {
    if (!items || items.length === 0) return [];
    return items.sort((a, b) => {
      const dateA = new Date(a[dateField]).getTime();
      const dateB = new Date(b[dateField]).getTime();
      return order === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }

}
