import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {LoginResponse} from '../domain/login-response';
import {LoginRequest} from '../domain/login-request';
import {User} from '../domain/user';
import {Router} from '@angular/router';
import {Office} from '../domain/office';
import {Store} from '@ngxs/store';
import {SesionState} from '@app/state/sesion/sesion.state';
import {ClearSesion} from '@app/state/sesion/sesion.actions';
import {Company} from '@app/domain/company';


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient, private router: Router,
                private store: Store) {
    }

    getToken(): string {
       return this.getLogin() ? `${this.getLogin().tokenType} ${this.getLogin().accessToken}` : null;
    }

    getUser(): User {
        return this.getLogin() ? this.getLogin().user : null;
    }

    getLogin(): LoginResponse {
        return  this.store.selectSnapshot(SesionState.getLogin);
    }

    getCompanyInfo(): string {
        const info = {id: this.getCompanySelected().id, ruc: this.getCompanySelected().ruc,
            principal: this.getCompanySelected().id === this.getUser().empresa.id && this.getCompanySelected().ruc === this.getUser().empresa.ruc};
        return JSON.stringify(info);
    }

    getCompanySelected(): Company {
        let company = this.store.selectSnapshot(SesionState.getCompanySelected);
        if (!company) {
            company = this.getUser().empresa;
        }
        return company;
    }

    hasPermission(permission: string): boolean {
        const permissions = this.store.selectSnapshot(SesionState.getRoles);
        return permissions && permissions.find(p => p === permission) !== undefined;
    }

    logIn(requestLogin: LoginRequest): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.baseUrl}/auth/login`, requestLogin,{ withCredentials: true })
    }

    refresh(): Observable<any> {
        return this.http.post(`${this.baseUrl}/auth/refresh`,{},{ withCredentials: true })
    }

    getPuntosVentaUsuario(): Office[] {
        return this.getLogin().user.puntosVenta;
    }

    logOut(): void {
        this.store.dispatch(new ClearSesion());
        this.router.navigateByUrl('/login');
        this.deleteCookie('refreshToken');
    }

    deleteCookie(name: string) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }


}
