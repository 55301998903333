import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {catchError} from 'rxjs';
import {LoginService} from '../service/login.service';
import {MessageService} from 'primeng/api';
import {LoaderRequestService} from '../service/loader-request.service';
import {Router} from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginResponse } from '@app/domain/login-response';
import { SetAccessToken, SetLogin } from '@app/state/sesion/sesion.actions';
import { SesionState } from '@app/state/sesion/sesion.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    public auth: LoginService,
    private messageService: MessageService,
    private loaderService: LoaderRequestService,
    private router: Router,
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.getToken()) {
      request = this.addTokenToRequest(request,null);
    }

    this.showLoader(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd(request);
        }
        return event;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.onEnd(request);
        console.log('Login dentro del error ==>', this.store.selectSnapshot( SesionState.getLogin ));
        
        console.log('Error detectado==>', errorResponse.status, 'IsRefreshing==>', this.isRefreshing);
        
        if (errorResponse.status === 401) {
          return this.handle401Error(request, next);
        } else {
          this.handleErrorMessages(errorResponse);
          return throwError(errorResponse);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string | null = null): HttpRequest<any> {
    const authToken = token ? `Bearer ${token}` : this.auth.getToken();
    return request.clone({
      setHeaders: {
        Authorization: authToken,
        companyInfo: this.auth.getCompanyInfo()
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null); // Reseteamos el valor del subject

      return this.auth.refresh().pipe(
        switchMap((newTokens: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newTokens.accessToken); // Emitimos el nuevo token

          this.store.dispatch(new SetAccessToken(newTokens.accessToken));
        console.log('Login luego del  refresh==>', this.store.selectSnapshot( SesionState.getLogin ));


          return next.handle(this.addTokenToRequest(request,newTokens.accessToken));
        }),
        catchError(() => {
          this.isRefreshing = false;
          this.router.navigateByUrl('/logout');
          return throwError('No se pudo refrescar el token');
        })
      );
    } else {
      // Esperamos a que el nuevo token esté disponible
      return this.refreshTokenSubject.pipe(
        filter(token => token != null), // Filtramos cuando el nuevo token esté disponible
        take(1), // Solo tomamos el primer valor emitido
        switchMap(token => next.handle(this.addTokenToRequest(request, token)))
      );
    }
  }

  private handleErrorMessages(errorResponse: HttpErrorResponse): void {
    if (errorResponse && errorResponse.error && errorResponse.error.mensaje) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errorResponse.error.mensaje });
    } else {
      if (errorResponse.error) {
        if (errorResponse.error.message) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: errorResponse.error.message });
        }
        if (errorResponse.error.error === 'Unauthorized') {
          this.router.navigateByUrl('/logout');
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error' });
      }
    }
  }

  private onEnd(request: HttpRequest<any>): void {
    this.hideLoader(request);
  }

  private showLoader(request: HttpRequest<any>): void {
    const background = request.method === 'GET';
    const hideLoader = request.headers.get('hideLoader');
    const isPrinting = request.headers.get('isPrinting');
    if (!hideLoader) {
      this.loaderService.call({
        show: true,
        background
      });
    }
    if (isPrinting) {
      this.loaderService.call({
        show: true,
        background: true
      });
    }
  }

  private hideLoader(request: HttpRequest<any>): void {
    const background = request.method === 'GET';
    const hideLoader = request.headers.get('hideLoader');
    const isPrinting = request.headers.get('isPrinting');
    if (!hideLoader) {
      this.loaderService.call({
        show: false,
        background
      });
    }
    if (isPrinting) {
      this.loaderService.call({
        show: false,
        background: true
      });
    }
  }
}