import {LoginResponse} from '@app/domain/login-response';
import {Company} from '@app/domain/company';
import { WhatsappSessionStatusI } from '@app/domain/whatsapp-status-session';

export class SetOffice {
    static readonly type = '[Sesion] SetOffice';

    constructor(public payload: any) {
    }
}

export class SetSaleStation {
    static readonly type = '[Sesion] SetSaleStation';

    constructor(public payload: any) {
    }
}

export class SetConfig {
    static readonly type = '[Sesion] SetConfig';

    constructor(public payload: any) {
    }
}

export class SetLogin {
    static readonly type = '[Sesion] SetLogin';

    constructor(public login: LoginResponse) {
    }
}

export class SetRoles {
    static readonly type = '[Sesion] SetRoles';

    constructor(public roles: string[]) {
    }
}

export class ClearSesion {
    static readonly type = '[Sesion] ClearSesion';

    constructor() {
    }
}

export class SetCompany {
    static readonly type = '[Sesion] SetCompany';
    constructor(public company: Company) {
    }
}


export class SetCompanySelected {
    static readonly type = '[Sesion] SetCompanySelected';
    constructor(public companySelected: Company) {
    }
}



export class SetAppVersion {
    static readonly type = '[Sesion] SetAppVersion';
    constructor(public appVersion: string) {
    }
}

export class SetStatusClientWhatsappSession {
    static readonly type = '[Sesion] SetStatusClientWhatsappSession';
    constructor(public statusClientWhatsappSession: WhatsappSessionStatusI) {
    }
}

export class SetStatusClientWhatsappQr {
    static readonly type = '[Sesion] SetStatusClientWhatsappQr';
    constructor(public statusClientWhatsappQr: boolean) {
    }
}
export class SetStatusClientWhatsappSocket {
    static readonly type = '[Sesion] SetStatusClientWhatsappSocket';
    constructor(public statusClientWhatsappSocket: boolean) {
    }
}

export class SetAccessToken {
    static readonly type = '[Sesion] SetAccessToken';

    constructor(public accessToken: string) {
    }
}
