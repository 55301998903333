import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Customer} from '../domain/customer';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findDistinctsCustomers(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/different-customers`);
    }

    findAllPageC(params: string): Observable<Page<Customer>> {
        return this.findAllC(params) as Observable<Page<Customer>>;
    }

    findAllC(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/customers?${params}`);
    }

    findAllCustomersByMonthBirth(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/customers/operations/events-by-birth?${params}`);
    }

    findById(id: number): Observable<Customer> {
        return this.http.get<Customer>(`${this.baseUrl}/customers/${id}`);
    }

    save(model: Customer): Observable<Customer> {
        return this.http.post<Customer>(`${this.baseUrl}/customers`, model);
    }

    importFromExcel(formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/customers/operations/import`, formData);
    }

    update(model: Customer): Observable<Customer> {
        return this.http.put<Customer>(`${this.baseUrl}/customers`, model);
    }

    updatePorDefecto(id, model: Customer): Observable<Customer> {
        return this.http.patch<Customer>(`${this.baseUrl}/customers/${id}/default`, model);
    }


    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/customers/${id}`);
    }

}
